html {
  box-sizing: border-box;
  font-family: "Inter", "Noto Sans TC", sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: auto;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html,
body,
#root {
  height: 100%;
}

button,
input,
optgroup,
select,
textarea {
  line-height: 1;
}
